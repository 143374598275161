<template>
  <div class="fill-height">
    <router-view />
  </div>
</template>

<script setup>
import { amplitudeComposable } from '@/helpers/composables/amplitude.js';

const { trackPage } = amplitudeComposable();

trackPage();
</script>

<style></style>
